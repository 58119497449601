<template>
  <div>
    <span class="metaDescription">{{ description }} : </span>
    <span class="synchroOk" v-if="isSynchronized">Ok</span>
    <span class="synchroNok" v-else>
      Nok !
      <b-button @click="synchronizeNow">Sync.</b-button>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    description: { type: String, required: true},
    isSynchronized: { type: Boolean, required: true},
  },
  methods:{
    synchronizeNow(){
      this.$emit('synchronizeNow');
    }
  }
}
</script>
