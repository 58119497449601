<template>
  <div>
    <fieldset class="fieldset">
        <legend>Etat des synchronisations</legend>
        <div class="NetNotAvailable">
            La connexion internet n'est pas disponible pour le moment.
            <b-button @click="refresh">Ré-essayer</b-button>
        </div>
          <meta-synchro-state
            description = "Méta-données"
            :isSynchronized = "areMetaUptodate"
            @synchronizeNow = "synchronizeMeta"
          />

          <meta-synchro-state
            description = "Banque de données des figues imposées"
            :isSynchronized = "areRefCompulsoriesUptodate"
            @synchronizeNow = "synchronizeRefCompulsories"
          />

          <meta-synchro-state
            description = "Configuration pour l'année en cours"
            :isSynchronized = "isSeasonConfigurationUptodate"
            @synchronizeNow = "synchronizeSeasonConfiguration"
          />

          <meta-synchro-state
            description = "Figures imposées sélectionnées pour l'année"
            :isSynchronized = "areSeasonCompulsoriesUptodate"
            @synchronizeNow = "synchronizeSeasonCompulsories"
          />

          <meta-synchro-state
            description = "Règles applicables pour l'année"
            :isSynchronized = "areSeasonRulesUptodate"
            @synchronizeNow = "synchronizeSeasonRules"
          />

          <meta-synchro-state
            description = "Données des membres du staff"
            :isSynchronized = "areStaffUptodate"
            @synchronizeNow = "synchronizeStaffMembers"
          />

          <meta-synchro-state
            description = "Données des compétiteurs"
            :isSynchronized = "areCompetitorsUptodate"
            @synchronizeNow = "synchronizeCompetitors"
          />

          <b-button
            v-if="atLeastTwoNeedSynchronization"
            @click="synchronizeAll">
            Synchroniser toutes les données non à jour
          </b-button>

    </fieldset>
</div>
</template>

<script>
import { mapActions, mapGetters,  } from 'vuex';
import MetaSynchroState from "@/components/MetaSynchroState";

export default {
  components: { MetaSynchroState },
    data(){
        return {
            syncMeta : false,
            syncMetaDone : false,
            syncMetaFailure : false,
            syncRefCompulsories : false,
            syncRefCompulsoriesDone : false,
            syncRefCompulsoriesFailure : false,
            syncSeasonConfiguration: false,
            syncSeasonConfigurationDone: false,
            syncSeasonConfigurationFailure: false,
            syncSeasonCompulsories : false,
            syncSeasonCompulsoriesDone : false,
            syncSeasonRules : false,
            syncSeasonRulesDone : false,
            syncStaff : false,
            syncStaffDone: false,
            syncCompetitors : false,
            syncCompetitorsDone : false,
        };
    },
    computed:{
        ...mapGetters('synchro', ['isNetAvailable', 'areCompetitorsUptodate', 'areMetaUptodate', 'areRefCompulsoriesUptodate', 'isSeasonConfigurationUptodate', 'areSeasonCompulsoriesUptodate', 'areSeasonRulesUptodate', 'areStaffUptodate']),
        atLeastTwoNeedSynchronization(){
          var count = (this.areMetaUptodate ? 0 : 1)
                    + (this.areRefCompulsoriesUptodate ? 0 : 1)
                    + (this.isSeasonConfigurationUptodate ? 0 : 1)
                    + (this.areSeasonCompulsoriesUptodate ? 0 : 1)
                    + (this.areSeasonRulesUptodate ? 0 : 1)
                    + (this.areStaffUptodate ? 0 : 1)
                    + (this.areCompetitorsUptodate ? 0 : 1);
          return count > 1;
        }
    },
    methods:{
        ...mapActions('synchro', ['checkForUpdates', 'synchronizeMeta', 'synchronizeRefCompulsories', 'synchronizeSeasonConfiguration', 'synchronizeSeasonCompulsories', 'synchronizeSeasonRules', 'synchronizeStaffMembers', 'synchronizeCompetitors']),

        refresh(){
            /*this.syncMeta = false;
            this.syncMetaDone = false;
            this.syncRefCompulsories = false;
            this.syncRefCompulsoriesDone = false;
            this.syncSeasonConfiguration = false;
            this.syncSeasonConfigurationDone = false;
            this.syncSeasonCompulsories = false;
            this.syncSeasonCompulsoriesDone = false;
            this.syncSeasonRules = false;
            this.syncSeasonRulesDone = false;
            this.syncStaff = false;
            this.syncStaffDone = false;
            this.syncCompetitors = false;
            this.syncCompetitorsDone = false;*/

            this.checkForUpdates();

/*            if(this.isNetAvailable && !this.areMetaUptodate)
            {
                this.syncMeta = true;
                this.$forceUpdate = true;
                if(this.synchronizeMeta())
                    this.syncMetaDone = true;
                else
                    this.syncMetaFailure = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.areRefCompulsoriesUptodate)
            {
                this.syncRefCompulsories = true;
                this.$forceUpdate = true;
                this.synchronizeRefCompulsories();
                this.syncRefCompulsoriesDone = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.isSeasonConfigurationUptodate)
            {
                this.syncSeasonConfiguration = true;
                this.$forceUpdate = true;
                this.synchronizeSeasonConfiguration();
                this.syncSeasonConfigurationDone = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.areSeasonCompulsoriesUptodate)
            {
                this.syncSeasonCompulsories = true;
                this.$forceUpdate = true;
                this.synchronizeSeasonCompulsories();
                this.syncSeasonCompulsoriesDone = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.areSeasonRulesUptodate)
            {
                this.syncSeasonRules = true;
                this.$forceUpdate = true;
                this.synchronizeSeasonRules();
                this.syncSeasonRulesDone = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.areStaffUptodate)
            {
                this.syncStaff = true;
                this.$forceUpdate = true;
                this.synchronizeStaffMembers();
                this.syncStaffDone = true;
                this.$forceUpdate = true;
            }

            if(this.isNetAvailable && !this.areCompetitorsUptodate)
            {
                this.syncCompetitors = true;
                this.$forceUpdate = true;
                this.synchronizeCompetitors();
                this.syncCompetitorsDone = true;
                this.$forceUpdate = true;
            }*/
        },
        synchronizeAll(){

            if(!this.areMetaUptodate)
            {

              this.synchronizeMeta();
            }

            if(!this.areRefCompulsoriesUptodate)
            {
                this.synchronizeRefCompulsories();
            }

            if(!this.isSeasonConfigurationUptodate)
            {
                this.synchronizeSeasonConfiguration();
            }

            if(!this.areSeasonCompulsoriesUptodate)
            {
                this.synchronizeSeasonCompulsories();
            }

            if(!this.areSeasonRulesUptodate)
            {
               this.synchronizeSeasonRules();
            }

            if(!this.areStaffUptodate)
            {
                this.synchronizeStaffMembers();
            }

            if(!this.areCompetitorsUptodate)
            {
                this.synchronizeCompetitors();
            }
        }
    },
    mounted(){
        this.refresh();
    }
}
</script>

<style scoped>
    .synchroMessage{ border:solid 2px var(--ffvlDanger); color: var(--fflvlDanger); font-style:italic; background: var(--ffvlOrangePicto); font-size: 1.1rem; }
</style>
